<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <h2 class="brand-text text-primary ml-1">
        {{ appName }}
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Token Login Habis
        </h2>
        <p class="mb-2">
          Oops! Token login kamu sudah habis nih
        </p>

        <section class="d-flex align-items-center justify-content-center">

          <b-button variant="outline-danger" class="mx-1 btn-sm-block" @click.prevent="logout">
            Keluar
          </b-button>
          <b-button variant="primary" class="mx-1 btn-sm-block" @click.prevent="refreshToken">
            {{ label }}
          </b-button>
        </section>

        <!-- image -->
        <b-img fluid :src="imgUrl" alt="Error page" />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import router from '@/router'

export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  methods: {
    refreshToken() {
      this.label = 'Loading...'
      useJwt.refreshToken()
        .then(() => {
          this.label = 'Update Token'
          const lastPathName = localStorage.getItem('lastPathName') ? localStorage.getItem('lastPathName') : 'my-dashboard'
          this.$router.push({ name: lastPathName })
        })
        .catch(e => {
          this.label = 'Update Token'
          this.displayError(e)
          this.logout()
        })
    },
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      this.$router.push({ name: 'auth-login' })
    }
  },
  data() {
    return {
      label: 'Update Token',
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    appName() {
      return $themeConfig.app.appName;
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>